import store from "@/state/store";
import back, {Back} from "@/helpers/back"
import router from "@/router"

export const isLoggedIn = () => !!store.state?.vars?.access

export const login = (email, password) => {
	localStorage.clear()
	new Back(
		'v1/login', 'post'
	)
		.setData({email: email, password: password})
		.run(r => {
			if(r?.access) {
				return store.dispatch("setVar", {access: r.access, refresh: r.refresh})
					.then(() => {
						return router.push("/")
					})
			}
		})
}

export const logout = () => {
	return store.dispatch("setVar", {access: null, refresh: null})
		.then(() => {
			localStorage.clear()
			return router.push("/login")
		})
}

export const me = () => {
	new Back('v1/users/me')
		.run(r => {
			return store.dispatch('setVar', {user: r})
		})
}

export const refreshAccess = () => {

}