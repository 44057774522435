import { createWebHistory, createRouter } from "vue-router";
import {isLoggedIn, logout} from "@/helpers/user";
import store from "@/state/store";

const router = createRouter({
	history: createWebHistory(),
	mode: 'history',
	routes: [
		{
			path: "/",
			name: "index",
			component: () => import("./views/HomePage.vue"),
			meta:{title:'Home Page'}
		},

		{
			path: "/klines-chart/:symbol",
			name: "klines-chart",
			props: true,
			component: () => import("./views/KlinesChart.vue"),
			meta:{title:'График инструмента в базе'}
		},
		{
			path: "/stats",
			name: "stats",
			component: () => import("./views/StatsPage"),
			meta:{title:'Статистика'}
		},
		{
			path: "/strategies-list",
			name: "strategies-list",
			component: () => import("./views/lists/StrategiesList"),
			meta:{title:'Стратегии'}
		},
		{
			path: "/positions-list",
			name: "positions-list",
			component: () => import("./views/lists/PositionsList"),
			meta:{title:'Позиции'}
		},
		{
			path: "/strategy-create",
			name: "strategy-create",
			component: () => import("./views/forms/StrategyForm.vue"),
			meta:{title:'Создание стратегии'}
		},
		{
			path: "/strategy-edit/:id",
			name: "strategy-edit",
			props: true,
			component: () => import("./views/forms/StrategyForm"),
			meta:{title:'Редактирование стратегии'}
		},
		{
			path: "/strategy-stats/:id",
			name: "strategy-stats",
			props: true,
			component: () => import("./views/StrategyStats"),
			meta:{title:'Статистика стратегии'}
		},
		{
			path: "/strategy-roll/:id",
			name: "strategy-roll",
			props: true,
			component: () => import("./views/StrategyRoll"),
			meta:{title:'Strategy Rolling'}
		},

		{
			path: "/users-list",
			name: "users-list",
			component: () => import("./views/lists/UsersList"),
			meta:{title:'Список Пользователей'}
		},
		{
			path: "/user-edit/:id",
			name: "user-edit",
			props: true,
			component: () => import("./views/forms/UserForm.vue"),
			meta:{title:'Редактирование Пользователя'}
		},
		{
			path: "/user-create",
			name: "user-create",
			component: () => import("./views/forms/UserForm.vue"),
			meta:{title:'Регистрация Пользователя'}
		},


		{
			path: "/keys-create",
			name: "keys-create",
			component: () => import("./views/forms/KeysForm.vue"),
			meta:{title:'Создание Ключей'}
		},
		{
			path: "/keys-list",
			name: "keys-list",
			component: () => import("./views/lists/KeysList"),
			meta:{title:'Список Ключей'}
		},
		{
			path: "/keys-edit/:id",
			name: "keys-edit",
			props: true,
			component: () => import("./views/forms/KeysForm.vue"),
			meta:{title:'Редактирование Ключей'}
		},

		{
			path: "/pool-create",
			name: "pool-create",
			component: () => import("./views/forms/PoolForm.vue"),
			meta:{title:'Создание Пула'}
		},
		{
			path: "/pools-list",
			name: "pools-list",
			component: () => import("./views/lists/PoolsList"),
			meta:{title:'Список Пулов'}
		},
		{
			path: "/pool-edit/:id",
			name: "pool-edit",
			props: true,
			component: () => import("./views/forms/PoolForm.vue"),
			meta:{title:'Редактирование Пула'}
		},

		{
			path: "/symbols-status",
			name: "symbols-status",
			props: true,
			component: () => import("./views/SymbolsStatus"),
			meta:{title:'Отслеживаемые Пары'}
		},
		{
			path: "/platform-maintenance",
			name: "platform-maintenance",
			component: () => import("./views/PlatformMaintenance"),
			meta:{title:'Обслуживание Платформы'}
		},
		{
			path: "/trading-desk",
			name: "trading-desk",
			component: () => import("./views/TradeDesk"),
			meta:{title:'Торговля'}
		},

		{
			path: "/droplet-create",
			name: "droplet-create",
			component: () => import("./views/forms/DropletForm.vue"),
			meta:{title:'Добавление Дроплета'}
		},
		{
			path: "/droplet-edit/:id",
			name: "droplet-edit",
			props: true,
			component: () => import("./views/forms/DropletForm.vue"),
			meta:{title:'Редактирование Дроплета'}
		},
		{
			path: "/droplets-list",
			name: "droplets-list",
			component: () => import("./views/lists/DropletsList.vue"),
			meta:{title:'Список Дроплетов'}
		},

		{
			path: "/login",
			name: "login",
			component: () => import("./views/forms/LoginForm.vue"),
			meta:{title:'Авторизация', allowGuest: true}
		},
		{
			path: "/signup-789548",
			name: "signup",
			component: () => import("./views/forms/SignupForm"),
			meta:{title:'Регистрация', allowGuest: true}
		},

		{
			path: "/error404",
			name: "error-404",
			component: () => import("./views/errors/ErrorPage404.vue"),
			meta:{title:'404 | Not Found', allowAll: true}
		},
		{
			path: "/error403",
			name: "error-403",
			component: () => import("./views/errors/ErrorPage403.vue"),
			meta:{title:'403 | Forbidden', allowAll: true}
		},
	]
})

router.beforeEach(async (routeTo, routeFrom) => {
	const allowAll = routeTo.matched.some((route) => route?.meta?.allowAll)
	const allowGuest = routeTo.matched.some((route) => route?.meta?.allowGuest)

	if(!allowAll && !isLoggedIn() && !allowGuest) {
		return '/login'
	}

	if(allowGuest && isLoggedIn() && !allowAll) {
		return store.dispatch('setErrorMessage', "Вы уже авторизованы")
							.then( () => "/" )
	}
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  document.title = "1B " + routeTo.meta.title + " v." + process.env.VUE_APP_VERSION
  next()
})

export default router