import axios from "axios";
import store from "@/state/store";
import {logout} from "@/helpers/user";
import router from "@/router";

export class Back {
	constructor(endpoint, method = "get") {
		this.endpoint = endpoint
		this.data = null
		this.method = method
		this.loading_key = "loading"
		this.headers = null
	}

	setData(data) {
		this.data = data
		return this
	}

	setLoadingKey(loading_key) {
		this.loading_key = loading_key
		return this
	}

	setHeaders(headers) {
		this.headers = headers
		return this
	}

	run(callback = null) {
		// для проброса доменов в докер, с унификацией фронта и работы надеве без прописывания доменов и протоколов
		// ! не забудь подкорректировать pycharm !!

		// let port = process.env?.VUE_APP_IS_DEV === '1' ? ':8000' : ''
		// let backend_url = `${process.env?.VUE_APP_BACK_URL}`
		let backend_url = `${process.env?.VUE_APP_BACK_PROTOCOL}://${process.env?.VUE_APP_BACK_HOST}` + '/api/'

		let args = {
			headers:  this.headers ?? {'content-type': 'application/json'},
			// url: process.env.VUE_APP_BACKEND_URL + endpoint,
			url: backend_url + this.endpoint,
			method: this.method,
		}

		// console.log(args)

		// Чтобы не запрашивать null abkmnhs
		let data = this.data ? {...this.data} : {} // чтобы при чистке null не реактивить
		for(let k in data) {
			if(data[k] === null) delete data[k]
			if(data[k] === 'null') data[k] = null // чтобы сбрасывать значения
		}
		args[this.method === 'get' ? 'params' : 'data'] = data

		// if (!store.state.vars?.access && args.url.includes('token/')) return router.push('/login')
		if(store.state.vars?.access) args.headers['Authorization'] = 'Bearer ' + store.state.vars?.access

		let promise = new Promise((resolve, reject) => {
			store.dispatch('clearMessage')
			store.dispatch('setLoading', this.loading_key)

			axios(args)
				.then(r => {
					resolve(r.data)
				})
				.catch(r => {
					if(r?.response?.status === 401) {
						return store.dispatch('setErrorMessage', "Нужно авторизоваться")
							.then( () => logout() )
					}

					if(r?.response?.status === 404) {
						return store.dispatch('setErrorMessage', `Не найдено ${args.url}`)
							// .then( () => router.push("/error404") )
					}

					if(r?.response?.status === 403) {
						return store.dispatch('setErrorMessage', "Forbidden")
							.then( () => router.push("/error403") )
					}

					let message = "Error unknown"

					if(r?.response?.data?.detail) {
						message = r?.response?.data?.detail
					}
					else if(r?.response?.data?.message) {
						message = r?.response?.data?.message
					}
					else if(r?.message) {
						message = r?.message
					}
					else if(typeof r?.data === "object") {
						message = Object.entries(r.data).map(([k,v]) => {
							return `${k} : ${v}`
						}).join(", ")
					}

					return store.dispatch('setErrorMessage', message)
				})
				.finally( () => {
					store.dispatch('stopLoading', this.loading_key)
				})
		})

		return callback ? promise.then(callback) : promise
	}
}

export default function back(endpoint, _data = null, method = "get", loading_key = "loading") {
	return new Back(endpoint, method).setData(_data).setLoadingKey(loading_key).run()
}