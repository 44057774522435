import { createStore } from 'vuex'
import VuexPersist from 'vuex-persist';
// https://github.com/softvar/secure-ls
import SecureLS from "secure-ls";
let ls = new SecureLS({ isCompression: false });


const vuexLocalStorage = new VuexPersist({
  key: 'stftstorage',
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
  // storage: window.localStorage, // or window.sessionStorage or localForage
})

const state = {
  message: null,
  modal: {visible: false, title: ".", content: "."},
  loading: {},
  vars : {},
  access:null,
  refresh:null,
}

const getters = {
  getLoading : (state) => state.loading,
  getVars: (state) => state.vars
}

const actions = {
  setErrorMessage : ({ commit }, payload) => commit('settingMessage', {text: payload, status: 'danger'}),
  setSuccessMessage : ({ commit }, payload) => commit('settingMessage', {text: payload, status: 'success'}),
  setInfoMessage : ({ commit }, payload) => commit('settingMessage', {text: payload, status: 'info'}),
  clearMessage : ({ commit }) => commit('settingMessage', null),

  setModal: ({commit}, payload) => commit('settingModal', payload),

  setLoading: ({commit}, payload) => commit('settingLoading', payload),
  stopLoading: ({commit}, payload) => commit('stoppingLoading', payload),
  setVar: ({commit}, payload) => commit('settingVar', payload),

}

const mutations = {
  settingMessage: (state, message) => state.message = message,
  settingLoading: (state, message) => {
    // state.loading[message] = true
    state.loading = Object.assign( {}, state.loading, {[message]:true})
    // нужен всегда для отображения загрузки
    if(!("loading" in state.loading)) state.loading = Object.assign( {}, state.loading, {["loading"]:true})
  },
  stoppingLoading: (state, message) => {
    if(message in state.loading) delete state.loading[message]
    if(Object.keys(state.loading).length === 1 && ("loading" in state.loading) ) delete state.loading["loading"]
  },
  settingVar: (state, payload) => state.vars = Object.assign({}, state.vars, payload),
  settingModal: (state, payload) => state.modal = Object.assign({}, state.modal, payload)
}

const store = createStore({
  state,
  getters,
  actions,
  mutations,
  plugins: [vuexLocalStorage.plugin]
})

export default store